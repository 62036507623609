<template>
  <v-container class="container--fluid grid-list-md">
    <h2 class="mb-2">{{$t('route.excels.import')}}</h2>
    <a href="https://pricecreator-rozetka.tatet.net/seller/scexcel/import">Ссылка</a><br>
    <a href="https://pricecreator-rozetka.tatet.net/seller/mpexcel">Ссылка</a>
  </v-container>
</template>

<script>
export default {
name: "importExc"
}
</script>

<style scoped>

</style>
